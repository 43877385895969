const showImage = {
	elem: {
		$images: document.querySelectorAll('figure[data-animate="true"] img'),
	},

	init() {
		if (!this.elem.$images.length) {
			return;
		}
		//alert(this.elem.images.length);
		const lImages = [...this.elem.$images];
		// console.log(lImages);
		const lObserver = new IntersectionObserver(
			(pItems) => {
				pItems.forEach((pItem, index) => {
					let lDelay = 1 * (index + 1);
					pItem.target.style.transitionDelay = `${lDelay}ms`;
					pItem.target.classList.toggle(
						'is-onscreen',
						pItem.isIntersecting
					);

					if (pItem.isIntersecting) {
						lObserver.unobserve(pItem.target);
					}
				});
			},
			{ threshold: 0 }
		);

		lImages.forEach((pItem) => {
			lObserver.observe(pItem);
		});
	},
};

export { showImage };
