/**
 * Full Image Gallery component.
 * @module components/media
 */
import { _extends } from 'brei-project-utils';

const media = {

	elem: {
		$body: $('body'),
		$slider: $('.media__items'),
		$is_home: $('.media--home')
	},

	options: {
		adaptiveHeight: true,
		dots: false,
		draggable: true,
		fade: true,
		infinite: true,
		responsive: [],
		rows: 0,
		slidesToScroll: 1,
		slidesToShow: 1,
		speed: 500,
		appendArrows: false,
		// appendArrows: $('#media_controls'),
		// appendArrows: $('.media-controls__lg'),
		// prevArrow: '<button tabindex="0" class="media__next btn btn--medium"><span class="show-for-sr">Go to the next slide</span><svg class="brei-icon brei-icon-chevron"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#brei-icon-chevron"></use></svg></button>',
		// nextArrow: '<button tabindex="1" class="media__prev btn btn--medium"><span class="show-for-sr">Go to the previous slide</span><svg class="brei-icon brei-icon-chevron"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#brei-icon-chevron"></use></svg></button>',
		x_of_x: null,
		_slider: null,
		customPaging: function (slider) {
			let lSlide = slider.slickCurrentSlide() + 1;
    		let lCount = slider.slideCount;
			/* This add slide x of x to desktop & mobile */
			if(media.elem.$is_home.length && lCount < 2){
				$($(media.elem.$is_home)[0]).find('.media__controls').css({display: 'none'});
			}
			this.x_of_x.text(`${lSlide} / ${lCount}`);
		},
		clickNav: function(e){
			e.preventDefault();
			let lNext = $(e.target).closest('div')[0].dataset.id === 'next';

			if(media.elem.$is_home.length){
				if (lNext) {
					media.elem.$slider.slick('slickNext')
				}else {
					media.elem.$slider.slick('slickPrev')
				}
			}else{
				/* Allows for multiple carousels on a page */
				let lData = $(e.target).closest('.media__wrapper')[0].dataset;
				if (lNext) {
					media.elem.$slider.eq(lData.carousel).slick('slickNext')
				} else {
					media.elem.$slider.eq(lData.carousel).slick('slickPrev')
				}
			}	
		}
	},



	init() {
		if (this.elem.$slider.length > 0) {
			this.activateSliders();
		}
	},

	activateSliders() {

		const _this = this;

		this.elem.$slider.each(function (index) {
			
			if($(this).children().length) {

				let prev = $(this).parents('.js-has-carousel').find('.js-prev');
				let next = $(this).parents('.js-has-carousel').find('.js-next');

				let options = _extends({}, _this.options);
				
				if (prev.length > 0) {
					options.prevArrow = prev;
				}

				if (next.length > 0) {
					options.nextArrow = next;
				}

				$(this).on('afterChange', function(event,slick,currentSlide) {
					let caption = $(this).find('.media__imagery').eq($(this).slick('slickCurrentSlide')).find('figcaption').html();
					if(caption === undefined) {
						caption = '';
					}
					/* Assign the x of x slide count */
					options.customPaging(slick, currentSlide);
					$(this).closest('.media').find('.media__caption').html(caption);
				}).on('init', function(event,slick,currentSlide) {
					let caption = $(this).find('.media__imagery').eq(0).find('figcaption').html();
					if(caption === undefined) {
						caption = '';
					}
					
					$(this).closest('.media').find('.media__caption').html(caption);
					
					let lMedia = $(this).closest('.media__wrapper');
					lMedia.attr('data-carousel', index);

					if(_this.elem.$is_home.length){
						lMedia.parent().find('.media__controls a').on('click', options.clickNav );
						options.x_of_x = $(this).closest('.media__inner').find('.media-amount');
					}else{
						/* Assign the x of x slide count */
						options.x_of_x = $(this).closest('.media__wrapper').find('.media-amount');
					
						/* Duplicae controls for small screens */
						let lControls = lMedia.find('[data-id="media_controls"]');
						let lItems = lMedia.find('.media__items');
						$(lControls).appendTo(lItems);

						/* Assign functions for small/large screens */
						lMedia.find('.media__controls a').on('click', options.clickNav );
					}
					options.customPaging(slick, currentSlide);
					

				}).slick(options);
				
			}
		});
	}

};

export { media };